@keyframes loaderAnimation {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.loader {
  background-image: linear-gradient(
    to right,
    theme('colors.blue-lighter') 0%,
    theme('colors.blue-darker') 50%,
    theme('colors.blue-lighter') 100%
  );
  animation: loaderAnimation 1.75s ease-in-out infinite;
}
